export const GENERAL_LINK_SCHEME = [
  {
    mainTitle: 'Products',
    innerTitle: 'Rates & features',
    link: '/products',
    isLink: true,
    isBlank: false,
  },
  {
    mainTitle: 'Criteria',
    innerTitle: 'Policy & packaging',
    link: '/criteria',
    isLink: true,
    isBlank: false,
  },
  {
    mainTitle: 'Calculators',
    innerTitle: 'Residential & Buy to let',
    isLink: true,
    link: '/calculator',
    isBlank: false,
  },
  {
    mainTitle: 'News',
    innerTitle: 'Latest updates',
    link: '/newsroom',
    isBlank: false,
    isLink: true,
  },
  {
    mainTitle: 'Broker support',
    innerTitle: 'Help & support',
    isLink: true,
    link: '/broker-support',
    isBlank: false,
  },
  {
    mainTitle: 'Meet the team',
    innerTitle: 'Profiles & contacts',
    isLink: true,
    link: '/meet-the-team',
    isBlank: false,
  },
];