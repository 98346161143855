export const MOBILE_SCHEME = [
  {
    title: 'Products',
    link: '/products',
    isLink: true,
  },
  {
    title: 'Criteria',
    link: '/criteria',
    isLink: true,
  },
  {
    title: 'Calculators',
    link: '/calculator',
    isLink: true,
  },
  {
    title: 'News',
    link: '/newsroom',
    isLink: true,
  },
  {
    title: 'Broker support',
    link: '/broker-support',
    isLink: true,
  },
  {
    title: 'Meet the team',
    link: '/meet-the-team',
    isLink: true,
  },
];