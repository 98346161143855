import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

const JumpToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const isBrowser = () => {
    return typeof window !== 'undefined';
  }

  useEffect(() => {
    const handleScroll = () => {
      if(isBrowser()){
        const scrollTop = window.scrollY;

        if (scrollTop > 200) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    if(isBrowser()){
      window.addEventListener('scroll', handleScroll);
    }
    
    return () => {
      if(isBrowser()){
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    if(isBrowser()){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    
  };

  return (
    <Link to="#" className={`scrolltotop ${isVisible ? 'scrolltotop--show' : ''}`} onClick={scrollToTop}>
      Back to top
    </Link>
  );
};

export default JumpToTop;
