import React, { useContext } from 'react';
import FooterLinks from '../footer-links/footer-links.component';
import { MenuContext } from '../../context';

const Footer = () => {

  const menuContext = useContext(MenuContext);

  return (
    <footer inert={menuContext.isMenuOpen ? 'true' : null}>
      <div className='footer-wrapper wrapper'>
        <nav>
          <FooterLinks/>
        </nav>
        <p className='copyright'>&copy; Copyright HSBC UK Bank plc {(new Date().getFullYear())} All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;