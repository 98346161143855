import React, { useContext, useEffect } from 'react';

import Header from './header/header.component';
import LiveChatBtn from './live-chat-btn/live-chat-btn.component';
import JumpToTop from './jump-to-top/jump-to-top.component';
import Footer from './footer/footer.component';
import { Helmet } from 'react-helmet';

import { MenuContext } from '../context';

export default function Layout(
  {
    title = 'Main page',
    metaDescription = 'This website is for the use of FCA authorised mortgage intermediaries only.',
    metaTitle = title + ' | HSBC for Intermediaries',
    disableWrapper = false,
    children,
  }) {

  let metaImage = "https://intermediaries.hsbc.co.uk/og/flyer.jpg";

  const menuContext = useContext(MenuContext);

  useEffect(() => {

    const meetTheTeamClassName = document.getElementsByClassName('meet-the-team');

    if (!!window.MSInputMethodContext && !!document.documentMode) {
      document.body.classList.add('ie11');
    } else if (meetTheTeamClassName.length > 0) {
      document.getElementById('main').classList.add('layout--fixed-width')
    }
  }, []);

  return (
    <main className='page'>
      <div className="skip-main visually-hidden-focusable overflow-hidden"
        inert={menuContext.isMenuOpen ? 'true' : null}
      >
        <div className="wrapper">
          <a href="#main">Skip to main content</a>
        </div>
      </div>
      <Helmet>
        <title>{title} | HSBC for Intermediaries</title>
        <meta name="description" content={metaDescription} />
        <meta charSet="utf-8" />
        <html lang="en-GB" />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="intermediaries.hsbc.co.uk" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://intermediaries.hsbc.co.uk/og/flyer.jpg" />
      </Helmet>
      <Header />
      <LiveChatBtn />
      <div className={`${!disableWrapper ? 'wrapper' : ''} layout`} id='main' inert={menuContext.isMenuOpen ? 'true' : null}>
        {children}
        <JumpToTop />
      </div>
      <Footer />
    </main>
  );
}