import React from "react";
import LINK_SCHEME from "./footer-links.utils";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { Link } from "gatsby";
import ExternalLink from "../external-link/external-link.component";
import { useLocation } from "@reach/router";

const FooterLinks = ({ links = LINK_SCHEME }) => {
  const cookieNoticeUrl = "https://www.hsbc.co.uk/cookie-notice";
  const location = useLocation();

  const handleClick = (isExternal, title) => {
    if (typeof window === "undefined" || !window.utag) return;

    const currentPath = location.href.split("/").pop() || "home";
    const cookiePage = isExternal ? cookieNoticeUrl.split("/").pop() : title;

    const eventDetails = {
      home: {
        pageType: "homepage",
        pageSubCategory: "Home",
        rawDataLayer: "10451v3",
        eventContent: `footer | Home Page : ${cookiePage}`,
      },
      products: {
        pageType: "product information",
        pageName: "products",
        pageSubCategory: "products",
        rawDataLayer: "10451v9",
        eventContent: `footer | Products : ${cookiePage}`,
      },
      criteria: {
        pageType: "product information",
        pageName: "criteria",
        pageSubCategory: "criteria",
        rawDataLayer: "10451v13",
        eventContent: `footer | Criteria : ${cookiePage}`,
      },
      calculator: {
        pageType: "product information",
        pageName: "calculator",
        pageSubCategory: "calculator",
        rawDataLayer: "10451v19",
        eventContent: `footer | Calculators : ${cookiePage}`,
      },
      newsroom: {
        pageType: "product information",
        pageName: "newsroom",
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v23",
        eventContent: `footer | News : ${cookiePage}`,
      },
      resources: {
        pageType: "product information",
        pageName: "resources",
        pageSubCategory: "resources",
        rawDataLayer: "10451v50",
        eventContent: `footer | Resources : ${cookiePage}`,
      },
      "our-latest-service-levels": {
        pageType: "product information",
        pageName: "our latest service levels",
        pageSubCategory: "our latest service levels",
        rawDataLayer: "10451v70",
        eventContent: `footer | Our Latest Service Levels : ${cookiePage}`,
      },
    };

    const config = eventDetails[currentPath];
    if (!config) {
      return;
    }

    const { eventContent, pageType, pageName = "", pageSubCategory, rawDataLayer } = config;

    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "onsite",
      eventContent,
      pageType,
      pageName,
      pageSubCategory,
      rawDataLayer,
    });
  };

  return (
    <ul className="footer-links">
      {links.map(({ title, href }, index) => (
        <li key={index}>
          {href.includes(cookieNoticeUrl) ? (
            <ExternalLink to={href} onClick={() => handleClick(true, title)}>
              {title}
            </ExternalLink>
          ) : (
            <Link to={href} onClick={() => handleClick(false, title)}>
              {title}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default FooterLinks;
