const LINK_SCHEME = [
  {
    href: '/legal-information#terms-conditions',
    title: 'Privacy & Terms of Use',
  },
  {
    href: '/legal-information#accessibility',
    title: 'Accessibility',
  },
  {
    href: '/legal-information#sitemap',
    title: 'Site map',
  },
  {
    href: 'https://www.hsbc.co.uk/cookie-notice/',
    title: 'Cookie policy',
  },
  {
    href: '/complaints',
    title: 'Customer complaints',
  },
];

export default LINK_SCHEME;